import "./intro.scss";

const Intro = () => {
  return (
    <div className="intro">
      <div className="explain">
        <h1>Explore, Craft, and Conquer!</h1>
        <a href="https://agelessrepublic.com/downloads/ageless_republic.apk" download>
          Download
          <div>- Updated on 7 Feb 2025 -</div>
        </a>
      </div>
    </div>
  );
};

export default Intro;